export const routePaths = {
  LANDING: '/',
  ROOT: '',
};

export const applicationRoutePaths = {
  ENCORE_START_URL: `${routePaths.ROOT}/business-details-one`, // this is a dummy URL to enable landing page then first prepop page
  BUSINESS_DETAILS_PART_ONE_URL: `${routePaths.ROOT}/`,
  BUSINESS_DETAILS_PART_TWO_URL: `${routePaths.ROOT}/business-details-two`,

  YOUR_DETAILS_PART_ONE_URL: `${routePaths.ROOT}/your-details`,
  YOUR_DETAILS_PART_TWO_URL: `${routePaths.ROOT}/your-details-two`,
  SHAREHOLDER_DETAILS_URL: `${routePaths.ROOT}/shareholder-details`,

  REVIEW_URL: `${routePaths.ROOT}/review`,
  EDIT_BUSINESS_DETAILS_URL: `${routePaths.ROOT}/edit-business-details`,
  EDIT_PERSONAL_DETAILS_URL: `${routePaths.ROOT}/edit-personal-details`,
  EDIT_BANKING_DETAILS_URL: `${routePaths.ROOT}/edit-banking-details`,

  YOUR_QUOTE_URL: `${routePaths.ROOT}/your-quote`,

  OPEN_BANKING_COMPLETE: `${routePaths.ROOT}/open-banking-complete`,

  BANKING_INFO: `${routePaths.ROOT}/banking-details`,

  SIGN_CONTRACTS: `${routePaths.ROOT}/sign-contracts`,

  COMPLETE: `${routePaths.ROOT}/complete`,
};
