import { usePathname } from 'next/navigation';
import { useJourneyFlow } from './useJourneyFlow';
import { JourneyFlow } from '../lib/journeyFlow';
import { applicationRoutePaths } from '../lib/pageroutes';

const flexiSteps: (string | string[])[] = [
  [
    applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL,
    applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL,
  ],
  [
    applicationRoutePaths.YOUR_DETAILS_PART_ONE_URL,
    applicationRoutePaths.YOUR_DETAILS_PART_TWO_URL,
    applicationRoutePaths.SHAREHOLDER_DETAILS_URL,
  ],
  applicationRoutePaths.YOUR_QUOTE_URL,
  applicationRoutePaths.BANKING_INFO,
  [
    applicationRoutePaths.REVIEW_URL,
    applicationRoutePaths.EDIT_BUSINESS_DETAILS_URL,
    applicationRoutePaths.EDIT_PERSONAL_DETAILS_URL,
    applicationRoutePaths.EDIT_BANKING_DETAILS_URL,
    applicationRoutePaths.COMPLETE,
  ],
];

const bcaSteps: (string | string[])[] = [
  [
    applicationRoutePaths.ENCORE_START_URL,
    applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL,
    applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL,
  ],
  [
    applicationRoutePaths.YOUR_DETAILS_PART_ONE_URL,
    applicationRoutePaths.YOUR_DETAILS_PART_TWO_URL,
    applicationRoutePaths.SHAREHOLDER_DETAILS_URL,
    applicationRoutePaths.REVIEW_URL,
    applicationRoutePaths.EDIT_BUSINESS_DETAILS_URL,
    applicationRoutePaths.EDIT_PERSONAL_DETAILS_URL,
  ],
  applicationRoutePaths.YOUR_QUOTE_URL,
  [applicationRoutePaths.BANKING_INFO, applicationRoutePaths.SIGN_CONTRACTS],

  [applicationRoutePaths.COMPLETE, applicationRoutePaths.OPEN_BANKING_COMPLETE],
];

const useSteps = () => {
  const pathname = usePathname();
  const flow = useJourneyFlow();
  const steps = flow === JourneyFlow.FLEXI ? flexiSteps : bcaSteps;

  const currentStep =
    steps.findIndex((step) => {
      if (Array.isArray(step)) {
        return step.includes(pathname);
      }
      return step === pathname;
    }) + 1;

  return {
    totalSteps: steps.length,
    currentStep,
  };
};

export { useSteps };
