import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import Image from 'next/image';
import { useSteps } from '../../../hooks/useSteps';
import Footer from '../Footer/Footer';
import './scrollbar.css';

const MAX_WIDTH = 'max-w-[700px]';

const LayoutFooter = ({
  partner,
  extraFooterCopy,
  footerHeadline,
}: {
  partner: string;
  extraFooterCopy?: string;
  footerHeadline?: string;
}) => {
  return (
    <footer className="block border border-l-0 border-b-0 border-r-0 border-t-core-5 laptop:ml-0">
      <Footer
        partner={partner}
        extraFooterCopy={extraFooterCopy}
        footerHeadline={footerHeadline}
      />
    </footer>
  );
};

const Header = ({ heading, partner }: { heading: string; partner: string }) => {
  const partnerSettings = getPartnerSettings(partner);
  const { currentStep, totalSteps } = useSteps();
  const steps = Array.from({ length: totalSteps }, (_, i) => (
    <span
      key={`step${i}`}
      className={`${
        i + 1 <= currentStep ? 'bg-primary' : 'bg-core-4'
      } flex-1 rounded-full h-[6px] `}
    />
  ));

  return (
    <header className={` bg-core-1 max-w-[${MAX_WIDTH}] mx-auto pt-6 pb-1 `}>
      <div className="grid grid-cols-[1fr_185px] ">
        <h1 className="order-2 pb-k-4 w-auto relative content-center ">
          <Image
            src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
              partnerSettings.images!.logo
            }`}
            alt={`${partnerSettings.displayName} and Liberis`}
            width={185}
            height={25}
            className="w-[125px] h-5 mobile:w-[160px] mobile:h-[26px] lmobile:w-[185px] lmobile:h-[30px] float-right"
            priority={true}
          />
        </h1>
        <h2 className="text-xs font-normal text-core-9 uppercase tracking-[2px] leading-5 pb-1 content-center">
          {heading}
        </h2>
      </div>
      <div className="col-span-2 flex flex-row gap-[11px] clear-both">
        {steps}
      </div>
    </header>
  );
};

const PageLayout = ({
  partner,
  children,
  extraFooterCopy,
}: {
  partner: string;
  extraFooterCopy?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="relative flex flex-col laptop:flex-row min-h-full overflow-hidden max-w-[1900px] mx-auto">
      {children}
      <div className="block laptop:hidden">
        <LayoutFooter partner={partner} extraFooterCopy={extraFooterCopy} />
      </div>
    </div>
  );
};

const Left = ({
  heading,
  children,
  partner,
  loading,
  growContainer = true,
  extraFooterCopy,
  footerHeadline,
}: {
  heading: string;
  children: React.ReactNode;
  partner: string;
  loading?: boolean;
  growContainer?: boolean;
  extraFooterCopy?: string;
  footerHeadline?: string;
}) => {
  const innerAttributes: any = {};
  if (loading) innerAttributes.role = 'status';

  return (
    <>
      {
        // for a hide and show on hover scrollbar add 'invisible hover:visible focus-within:visible focus:visible' below - design decision required
      }
      <div id="leftPane" className="flex-1 flex flex-col laptop:overflow-y-auto laptop:h-[100vh] scrollbar">
        <div className="visible ">
          <div
            className={`${MAX_WIDTH} mx-auto px-5 laptop:px-10 desktop:px-16 sticky top-0 z-10`}
          >
            <Header heading={heading} partner={partner} />
          </div>
          <main
            className={`group/main ${loading && 'loading'} ${
              growContainer || loading
                ? 'flex-1 laptop:min-h-[100vh]'
                : 'laptop:flex-1 laptop:min-h-[100vh]'
            }  ${MAX_WIDTH} mx-auto w-full pt-[18px] px-k-7 laptop:px-10 desktop:px-16 pb-16 laptop:pb-[112px] flex`}
            aria-label={`${heading} main content`}
          >
            <div
              {...innerAttributes}
              className={`${
                growContainer || loading ? 'flex-1' : 'laptop:flex-1'
              }  flex flex-col w-full`}
            >
              {loading && <span className="sr-only">Loading</span>}
              {children}
            </div>
          </main>
          <div className="hidden laptop:block ">
            <LayoutFooter
              partner={partner}
              extraFooterCopy={extraFooterCopy}
              footerHeadline={footerHeadline}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Right = ({
  hiddenOnMobile,
  children,
  isLoading = false,
}: {
  hiddenOnMobile?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <div
      className={`${
        hiddenOnMobile ? 'hidden laptop:block flex-1 ' : 'flex-1'
      }  min-w-[50%] laptop:min-h-[100vh] flex flex-col group/aside ${
        isLoading && 'loading max-h-0 laptop:max-h-auto overflow-hidden'
      }`}
    >
      <div className="laptop:p-8 flex-1 flex laptop:h-[calc(100vh)] box-border">
        {children}
      </div>
    </div>
  );
};

PageLayout.Left = Left;
PageLayout.Right = Right;
PageLayout.Header = Header;

export { PageLayout };
